<template>
  <div class="social-media">
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="15" fill="currentColor"/>
      <rect x="8" y="8" width="24" height="24" rx="4" stroke="white" stroke-width="2"/>
      <rect x="14" y="14" width="12" height="12" rx="6" stroke="white" stroke-width="2"/>
      <rect x="25" y="11" width="4" height="4" rx="2" fill="white"/>
    </svg>
    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="15" fill="currentColor"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M22.9732 20.587C22.8703 19.7638 23.0098 18.6718 23.4458 17.9719C23.6283 17.679 23.8562 17.4587 24.186 17.2989C24.5286 17.1328 25.1652 16.9445 26.2819 17.0143L29.7751 17.2326L30.2117 10.2463L26.7185 10.0279C22.3691 9.75609 19.2451 11.4768 17.5046 14.2703C16.0226 16.6489 15.8128 19.3711 16.0002 21.2164V22.0208H12V27.0208H16.0002V40.0208H23.0002V27.0208H26L27 22.0208H23.0002V20.8032L22.9732 20.587Z" fill="white"/>
    </svg>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="40" height="40" rx="15" fill="currentColor"/>
      <path d="M30.5 17.4019C32.5 18.5566 32.5 21.4434 30.5 22.5981L17 30.3923C15 31.547 12.5 30.1036 12.5 27.7942L12.5 12.2058C12.5 9.89637 15 8.45299 17 9.60769L30.5 17.4019Z" fill="white"/>
    </svg>
  </div>
</template>

<style lang="scss">
  @import '../styles/vars.scss';

  .social-media {
    @media only screen and (min-width: 0) {
      svg {
        margin-right: 20px;
        color: $orange;
        cursor: pointer;
      }
    }
  }
</style>
