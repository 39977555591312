<template>
  <section id="about" class="explore-hangman-page">
    <div>
      <h2>{{ c.newTitle }}</h2>
      <h3>{{ c.title }}</h3>
      <img src="../assets/explore-img.png" alt="Exploreaza lumea cuvintelor cu Hangman" />
      <div class="align">
        <img src="../assets/hangman3.png" alt="Jocul preferat Hangman" />
        <p>{{ c.p1 }}</p>
      </div>
      <p>{{ c.p2 }}</p>
      <p>{{ c.p3 }}</p>
      <img src="../assets/hangman3.png" alt="Jocul preferat Hangman" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import BaseButton from "@/components/BaseButton";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('exploreHangman'))
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .explore-hangman-page {
    @media only screen and (min-width: 0) {
      margin: 50px 23px 20px 23px;

      * {
        font-family: "Texturina", sans-serif;
        color: $brown;
      }

      > div {
        h2 {
          display: none;
        }

        h3 {
          font-weight: 900;
        }

        h3 + img {
          width: 169px;
          height: 164px;
          margin-top: -20px;
          left: 0;
          position: absolute;
        }

        .align {
          img {
            display: none;
          }
        }

        p {
          margin-bottom: 25px;
        }

        p + img {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 0 20px 0 0;

      > div {
        h2 {
          max-width: 650px;
          display: flex;
          align-items: flex-start;
          font-family: "Candal", sans-serif;
          font-size: 4.8rem;
          background-image: radial-gradient(115.2% 115.2% at 39.33% 24%, #00A6DB 0%, #52E0FF 50.5%, #008C9E 100%);
          -webkit-background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }

        h3 {
          margin-top: 0;
        }

        p {
          margin-bottom: -10px;
        }

        p + img {
          width: 103px;
          height: 73px;
          margin-left: -20px;
          display: flex;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        h3 {
          margin-bottom: 0;
        }

        .align {
          display: flex;

          img {
            width: 161px;
            height: 114px;
            margin: 10px -20px 0 -30px;
            display: flex;
            z-index: 1;
          }
        }

        p + img {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        h3 + img {
          margin-top: 130px;
        }

        .align {
          img {
            margin-right: 20px;
          }
        }
      }
    }
  }
</style>