<template>
  <div class="shop-page">
    <HeaderShop />

    <div>
      <div class="game-one">
        <h2>{{ c.game1 }}</h2>
        <img src="../assets/txto-pack.png" alt="">
        <div>
          <label for="game1-quantity">{{ c.quantity }}</label>
          <select v-model="selectedGame1Quantity" id="game1-quantity">
            <option v-for="n in 11" :key="n" :value="n - 1">{{ n - 1 }}</option>
          </select>
        </div>
      </div>

      <div class="game-two">
        <h2>{{ c.game2 }}</h2>
        <img src="../assets/hangman-package.png" alt="">
        <div>
          <label for="game2-quantity">{{ c.quantity }}</label>
          <select v-model="selectedGame2Quantity" id="game2-quantity">
            <option v-for="n in 11" :key="n" :value="n - 1">{{ n - 1 }}</option>
          </select>
        </div>
      </div>

      <BaseButton :text="c.cta" />
    </div>

    <img src="../assets/blue-design2.png" alt="" />
  </div>
</template>

<script setup>
  import {computed, ref} from 'vue';
  import {useStore} from 'vuex'
  import HeaderShop from "@/components/HeaderShop";
  import BaseButton from "@/components/BaseButton";

  const { getters } = useStore()
  const c = computed(() => getters.shopPage( 'chooseGame'))

  const selectedGame1Quantity = ref(0);
  const selectedGame2Quantity = ref(0);
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .shop-page {
    @media only screen and (min-width: 0) {
      background-color: $light-beige;
      overflow: hidden;

      > div {
        padding: 0 23px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .game-one, .game-two {
          display: flex;
          flex-direction: column;
          align-items: center;

          h2 {
            margin-top: 100px;
            align-self: flex-start;
          }

          img {
            width: 250px;
          }

          div {
            display: flex;
            align-items: center;

            select {
              width: 50px;
              margin-left: 10px;
              background-color: $blue2;
              color: white;
              font-weight: 500;
              padding: 5px 0 5px 5px;
              border: none;
              border-radius: 8px;
              cursor: pointer;
              font-size: 1.6rem;

              option {
                width: 50px;
              }
            }

            select:focus {
              width: 50px;
            }
          }
        }

        .game-one {
          margin-bottom: 40px;
        }

        .base-button {
          margin: 50px 0;
        }
      }

      div + img {
        height: 42px;
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        .game-one, .game-two {
          img {
            width: 356px;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        .game-one, .game-two {
          div {
            label {
              font-size: 2rem;
            }

            select {
              //width: 60px;
              //padding: 10px 40px;
              font-size: 2rem;

              option {
                //width: 60px;
                font-size: 1.6rem;
              }
            }

            select:focus {
              //width: 20px;
            }
          }
        }
      }
    }
  }
</style>

