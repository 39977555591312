<template>
  <section id="price" class="price">
    <div>
      <div class="shape">
        <div class="title">
          <img src="../assets/blue-shape5.png" alt="Txto pret" />
          <h2>{{ c.title }}</h2>
        </div>
        <div class="sub-title">
          <img src="../assets/circle.png" alt="Joc TXTO Junior" />
          <p v-html="c.subTitle" />
        </div>
      </div>

      <div class="text">
        <p v-html="c.p1" />
        <p v-html="c.p2" />
        <BaseButton :text="c.cta" @click="goToShop" />
      </div>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import {useRouter} from 'vue-router';
  import BaseButton from "@/components/BaseButton";

  const router = useRouter();
  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'price'))

  const goToShop = () => {
    router.push("/shop");
  };
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .price {
    @media only screen and (min-width: 0) {
      margin: 50px 23px 0 23px;

      > div {
        display: flex;
        flex-direction: column;

        b {
          font-weight: 700;
        }

        .shape {
          .title {
            margin: 30px -90px 0 0;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 0;

            img {
              width: 364px;
              height: 199px;
              position: absolute;
              z-index: 0;
            }

            h2 {
              max-width: 230px;
              margin-left: -50px;
              position: relative;
              color: white;
              z-index: 1;
            }
          }

          .sub-title {
            margin: -20px 0 0 210px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 120px;
              height: 120px;
              position: absolute;
            }

            p {
              max-width: 120px;
              padding: 0 10px;
              text-align: center;
              color: $dark-green;
              position: relative;
            }
          }
        }

        .text {
          margin-top: 20px;
          display: flex;
          flex-direction: column;

          p {
            color: $dark-green;
          }

          p + p {
            margin-top: 0;
          }

          button {
            margin-top: 20px;
            align-self: center;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 50px 16px 0 16px;

      > div {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        .shape {
          max-width: 65%;

          .title {
            margin: 80px 130px 0 0;

            img {
              width: 531px;
              height: 291px;
            }

            h2 {
              padding-bottom: 50px;
              margin-left: -160px;
            }
          }

          .sub-title {
            margin: -80px 0 0 -30px;

            img {
              width: 176px;
              height: 176px;
            }

            p, b {
              font-size: 2.4rem;
            }

            p {
              max-width: 200px;
            }
          }
        }

        .text {
          max-width: 35%;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin: 50px 90px 0 90px;

      > div {
        .shape {
          .title {
            margin: 80px 220px 0 0;
          }

          .sub-title {
            p {
              max-width: 200px;
            }
          }
        }

        .text {
          button {
            font-size: 2rem;
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      margin: 150px 0 0 133px;

      > div {
        .shape {
          .title {
            img {
              width: 864px;
              height: 473px;
            }

            h2 {
              margin-right: 160px;
            }
          }

          .sub-title {
            img {
              width: 273px;
              height: 273px;
            }

            p {
              padding: 0;
            }

            p, b {
              font-size: 3.6rem;
            }
          }
        }

        .text {
          b {
            font-size: 2.4rem;
          }
        }
      }
    }
  }
</style>