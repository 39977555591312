<template>
  <section id="home" class="header-hangman">
    <div>
      <img class="logo" src="../assets/logo-txto.png" alt="Txto Juniot joc pentru copii" />

      <img v-if="isMobile" class="menu-icon" @click="toggleMobileMenu"
           :src="isMobileMenuOpen ? './images/close-menu-hangman.png' : './images/menu-icon-hangman.png'" :class="{ 'close-menu-icon': isMobileMenuOpen, 'menu-icon': !isMobileMenuOpen }"
           alt="" />

      <transition name="fade">
        <div v-if="isMobile && isMobileMenuOpen" class="mobile-menu">
          <div class="white-container">
            <ul>
              <li v-for="page in c.pages" :key="page.id" @click="scrollToSection(page.id)">
                <span>{{ page.title }}</span>
              </li>
            </ul>
            <img src="../assets/salamandra.png" alt="" />
            <img src="../assets/salamandra2.png" alt="" />
          </div>
        </div>
      </transition>

      <ul v-if="!isMobile" class="desktop-menu">
        <li v-for="page in c.pages" :key="page.id">
          <a :href="'#' + page.id" @click.prevent="scrollToSection(page.id)">{{ page.title }}</a>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
  import {computed, ref, onMounted, onUnmounted, inject} from 'vue';

  import { useStore } from 'vuex'

  const { getters } = useStore()
  const c = computed(() => getters.hangmanPage( 'navigation'))

  const isMobileMenuOpen = ref(false);
  const isMobile= ref(false);

  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;

    if (isMobileMenuOpen.value) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  };

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 1023;
  };

  onMounted(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
    document.body.classList.remove('no-scroll');
  });

  const smoothScroll = inject('smoothScroll')

  function scrollToSection (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -100
    })
    isMobileMenuOpen.value = false;
    document.body.classList.remove('no-scroll');
  }
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .header-hangman{
    @media only screen and (min-width: 0) {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1000;

      > div {
        height: 95px;
        padding: 0 25px 10px 25px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $blue2;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        position: relative;
        z-index: 1;
        box-shadow: 2px 2px 5px 2px $beige;

        .logo {
          width: 58px;
          height: 22px;
          margin-top: 40px;
        }

        .menu-icon, .close-menu-icon {
          margin-top: 40px;
          cursor: pointer;
          z-index: 3;
        }

        .menu-icon {
          width: 49px;
          height: 46px;
        }

        .close-menu-icon {
          width: 35px;
          height: 35px;
          margin-right: 15px;
        }

        .mobile-menu {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          background-color: #008C9E80;
          z-index: 2;

          .white-container {
            width: 90%;
            height: 93%;
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-color: $light-blue;
            border-radius: 25px;
            z-index: 9;
            position: relative;

            ul {
              margin-top: 30px;
              list-style: none;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              z-index: 1;

              li {
                margin: 26px 7px 0 7px;

                span {
                  padding: 8px 15px;
                  font-weight: 900;
                  background-color: $beige;
                  color: $brown;
                  border-radius: 10px;
                  cursor: pointer;
                }
              }
            }

            ul + img {
              width: 100%;
              height: auto;
              position: absolute;
              bottom: 0;
              border-radius: 25px;
            }

            img + img {
              display: none;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        .mobile-menu {
          .white-container {
            width: 95%;
            height: 96%;

            ul + img {
              display: none;
            }

            img + img {
              width: 100%;
              height: auto;
              bottom: 0;
              position: absolute;
              display: flex;
              border-radius: 25px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        padding: 0 15px 10px 15px;
        align-items: baseline;

        .desktop-menu {
          padding-top: 0;
          display: flex;
          flex-wrap: wrap;
          list-style: none;

          li {
            margin: 8px 0 8px 8px;

            a {
              padding: 5px 15px;
              font-weight: 900;
              text-decoration: navajowhite;
              background-color: white;
              color: $brown;
              border-radius: 10px;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      > div {
        padding: 0 10px 0 48px;
        align-items: center;

        .logo {
          width: 100px;
          height: 33px;
          margin-top: 0;
        }

        .desktop-menu {
          li {
            margin: 8px 0 8px 15px;

            a {
              font-size: 2.4rem;
            }
          }
        }
      }
    }
  }
</style>