<template>
  <button class="base-button" v-html="text" @click="emits('click')" />
</template>

<script setup>
  defineProps({
    text: String,
  })
  const emits = defineEmits(['click'])
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .base-button {
    @media only screen and (min-width: 0px) {
      padding: 10px 25px;
      background-color: $orange;
      color: white;
      border: none;
      border-radius: 16px;
      font-weight: 700;
      cursor: pointer;
    }
  }
</style>
