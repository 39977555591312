<template>
  <section id="home" class="header-junior">
    <div>
      <img class="logo" src="../assets/logo-junior.png" alt="Txto Juniot joc pentru copii" />

      <img v-if="isMobile" class="menu-icon" @click="toggleMobileMenu"
           :src="isMobileMenuOpen ? './images/close-menu-icon.png' : './images/menu-icon.png'" :class="{ 'close-menu-icon': isMobileMenuOpen, 'menu-icon': !isMobileMenuOpen }"
           alt="" />

      <transition name="fade">
        <div v-if="isMobile && isMobileMenuOpen" class="mobile-menu">
          <div class="white-container">
            <ul>
              <li v-for="page in c.pages" :key="page.id" @click="scrollToSection(page.id)">
                <span>{{ page.title }}</span>
              </li>
            </ul>
            <img src="../assets/kids.png" alt="" />
          </div>
        </div>
      </transition>

      <ul v-if="!isMobile" class="desktop-menu">
        <li v-for="page in c.pages" :key="page.id">
          <a :href="'#' + page.id" @click.prevent="scrollToSection(page.id)">{{ page.title }}</a>
        </li>
      </ul>
    </div>
    <img class="rocket" src="../assets/rocket.png" alt="" />
  </section>
</template>

<script setup>
  import {computed, ref, onMounted, onUnmounted, inject} from 'vue';

  import { useStore } from 'vuex'

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'navigation'))

  const isMobileMenuOpen = ref(false);
  const isMobile= ref(false);

  const toggleMobileMenu = () => {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
  };

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 1023;
  };

  onMounted(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
  });

  const smoothScroll = inject('smoothScroll')

  function scrollToSection (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -60
    })
    isMobileMenuOpen.value = false;
  }
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .header-junior {
    @media only screen and (min-width: 0) {
      display: flex;
      flex-direction: column;

      > div {
        height: 95px;
        padding: 0 20px 0 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $blue2;
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 1000;
        box-shadow: 2px 2px 5px 2px $beige;

        .logo {
          width: 145px;
          height: 84px;
          margin-top: 40px;
          z-index: 3;
        }

        .menu-icon, .close-menu-icon {
          margin-top: 40px;
          cursor: pointer;
          z-index: 3;
        }

        .menu-icon {
          width: 40px;
          height: 40px;
        }

        .close-menu-icon {
          width: 40px;
          height: 40px;
          margin-right: 15px;
        }

        .mobile-menu {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          position: fixed;
          background-color: #008C9E80;
          z-index: 2;

          .white-container {
            width: 90%;
            height: 93%;
            padding-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            background-color: white;
            border-radius: 25px;
            z-index: 9;

            ul {
              margin-top: 50px;
              list-style: none;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;

              li {
                margin: 26px 7px 0 7px;

                span {
                  padding: 10px 25px;
                  font-weight: 700;
                  background-color: $blue;
                  color: white;
                  border-radius: 30px;
                }
              }
            }

            img {
              width: 100%;
              height: auto;
              border-bottom-right-radius: 25px;
              border-bottom-left-radius: 25px;
            }
          }
        }
      }

      .rocket {
        width: 80%;
        margin-top: 80px;
        height: auto;
        margin-left: -10px;
        align-self: center;
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        .mobile-menu {
          .white-container {
            width: 95%;
            height: 96%;

            ul {
              padding-top: 30px;
              li {
                span {
                  padding: 8px 16px;
                }
              }
            }
          }
        }
      }

      .rocket {
        margin-left: -60px;
      }
    }

    @media only screen and (min-width: 1024px) {
      .desktop-menu {
        padding-top: 0;
        display: flex;
        flex-wrap: wrap;
        list-style: none;

        li {
          margin: 8px 0 8px 20px;

          a {
            text-decoration: navajowhite;
            color: white;
            cursor: pointer;
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      .rocket {
        max-width: 1200px;
      }
    }
  }
</style>