<template>
  <section class="carousel">
    <div class="carousel-wrapper">
      <img class="blue-shape" src="../assets/blue-shape1.png" alt="" />
      <div class="align-carousel">
        <button
            @click="moveSlide('prev')"
            :disabled="currentIndex === 0"
            class="carousel-button left">
          <img src="../assets/icons/left-arrow.png" alt="Previous slide" />
        </button>
        <div class="carousel">
          <div id="slides">
            <div v-for="slide in slides" :key="slide.id" class="slide">
              <img class="slide-img" :src="slide.src" :alt="slide.alt" />
            </div>
          </div>
        </div>
        <button
            @click="moveSlide('next')"
            :disabled="currentIndex === slides.length - 1"
            class="carousel-button right">
          <img src="../assets/icons/right-arrow.png" alt="Next slide" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
import {ref, onMounted, nextTick, onBeforeUnmount} from 'vue';

  const props = defineProps({
    slides: Array,
  });

  const currentIndex = ref(Math.floor(props.slides.length / 2));
  const transform = ref(0);

  const calculateTransform = () => {
    const image = document.querySelector(".slide-img");
    if (!image) return;
    const width = image.clientWidth + 15;
    const slides = document.getElementById("slides");

    transform.value = -(currentIndex.value * width);
    slides.style.transform = `translateX(${transform.value}px)`;
  };

  const moveSlide = (direction) => {
    const image = document.querySelector(".slide-img");
    if (!image) return;
    const slides = document.getElementById("slides");
    const width = image.clientWidth + 15;

    if (direction === 'next' && currentIndex.value < props.slides.length - 1) {
      currentIndex.value++;
      transform.value -= width;
    } else if (direction === 'prev' && currentIndex.value > 0) {
      currentIndex.value--;
      transform.value += width;
    }

    slides.style.transform = `translateX(${transform.value}px)`;
  };

  onMounted(async () => {
    await nextTick();
    calculateTransform();

    window.addEventListener('resize', calculateTransform);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', calculateTransform);
  });
</script>

<style lang="scss">
  .carousel {
    @media only screen and (min-width: 0) {
      .carousel-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 274px;
          height: 246px;
          position: relative;
        }

        .align-carousel {
          display: flex;
          align-items: center;
          position: absolute;

          .carousel {
            width: 157px;
            overflow: hidden;

            #slides {
              display: flex;
              transition: transform 0.5s ease;
            }

            .slide {
              min-width: 100%;
              box-sizing: border-box;
              margin-right: 15px;
              margin-top: 25px;
            }

            .slide-img {
              width: 100%;
              height: 233px;
            }
          }

          .carousel-button {
            margin-top: 25px;
            background: none;
            cursor: pointer;
            //z-index: 10;

            img {
              width: 37px;
              height: 37px;
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }

          .left {
            margin-right: 10px;
          }

          .right {
            margin-left: 5px;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      .carousel-wrapper {
        img {
          width: 356px;
          height: 320px;
        }

        .align-carousel {
          .carousel {
            width: 204px;

            .slide-img {
              height: 303px;
            }
          }

          .carousel-button {
            margin-top: 30px;
            img {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      .carousel-wrapper {
        img {
          width: 484px;
          height: 435px;
        }

        .align-carousel {
          .carousel {
            width: 278px;

            .slide-img {
              height: 413px;
            }
          }

          .carousel-button {
            img {
              width: 65px;
              height: 65px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {}
  }
</style>
