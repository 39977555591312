<template>
  <footer class="home-footer">
    <div>
      <ul>
        <li v-for="page in c.pages" :key="page.id">
          <router-link :to="'/' + page.id">{{ page.title }}</router-link>
        </li>
      </ul>
      <img src="../assets/logo-txto.png" alt="Txto" />
    </div>
  </footer>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('footer'))
</script>

<style lang="scss">
  .home-footer {
    @media only screen and (min-width: 0) {
      margin-top: 130px;

      div {
        height: 197px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;
        background-image: url("../assets/home-footer.png");
        background-size: cover;
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;

        ul {
          display: flex;
          list-style: none;
          z-index: 1;

          li {
            a {
              margin-right: 16px;
              color: white;
              text-decoration: none;
              font-weight: 500;
              cursor: pointer;
            }
          }
        }

        img {
          width: 58px;
          height: 22px;
          margin: 20px 0 30px 0;
          z-index: 1;
        }
      }

      div:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 166, 219, 0) 17.5%, #00A6DB 100%);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;
        pointer-events: none;
        z-index: 0;
      }
    }

    @media only screen and (min-width: 768px) {
      div {
        height: 271px;

        ul {
          li {
            a {
              font-size: 2.4rem;
            }
          }
        }

        img {
          width: 140px;
          height: 53px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin-top: 160px;

      div {
        height: 279px;
      }
    }

    @media only screen and (min-width: 1440px) {
      margin-top: 190px;
    }
  }
</style>
