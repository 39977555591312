<template>
  <section id="faq" class="faq">
    <div>
      <h2>{{ c.title }}</h2>
      <div class="container">
        <FaqItem
            v-for="(question, index) in c.questions"
            :key="index"
            :question="question.question"
            :answer="question.answer" />
      </div>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import FaqItem from "@/components/FaqItem";

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'faq'))
</script>

<style lang="scss">
  .faq {
    @media only screen and (min-width: 0) {
      margin: 50px 23px 0 23px;

      > div {
        .container {
          .faq-item {
            > div {
              img {
                display: none;
              }
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 30px 16px 0 16px;

      > div {
        h2 {
          text-align: center;
        }

        .container {
          .faq-item {
            span {
              margin-left: 16px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin-top: 50px;

      > div {
        max-width: 900px;
        margin: 0 auto;
      }
    }

    @media only screen and (min-width: 1440px) {
      margin-top: 80px;

      > div {
        max-width: 950px;
      }
    }
  }
</style>