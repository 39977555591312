<template>
  <section id="contact-junior" class="contact-junior">
    <div>
      <h2>{{ c.title }}</h2>
      <p v-html="c.p" />

      <div class="overflow-hidden-wrapper">
      <div class="container" v-if="!submitted">
        <img src="../assets/blue-shape6.png" alt="Txto Junior Joc" />
        <form @submit.prevent="">
          <input id="email" type="text"
                    :placeholder="c.email" />
          <textarea id="msg"
                    :placeholder="c.message" />
          <button>{{ c.cta }}</button>
        </form>
      </div>

      <div v-else>
        <p class="thank-you" v-html="c.thankyouMessage" />
      </div></div>
    </div>
  </section>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('contact'))

  const submitted = ref(false);
</script>

<style lang="scss">
  .contact-junior {
    @media only screen and (min-width: 0) {
      margin: 10px 46px 0 46px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        .overflow-hidden-wrapper {
          position: relative;
          width: 100%;
        }

        h2 {
          margin: 0;
        }

        p {
          width: 226px;
          font-size: 1.4rem;
          z-index: 1;
        }

        b {
          font-weight: 700;
        }

        .container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          img {
            width: 592px;
            height: 417px;
            align-self: center;
            margin-top: -70px;
            margin-left: 90px;
            position: absolute;
            transform: rotate(-10deg);
          }

          form {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: start;

            input, textarea {
              width: 226px;
              margin-bottom: 15px;
              border-radius: 10px;
              border: none;
            }

            input {
              margin-top: 40px;
              height: 40px;
            }

            textarea {
              height: 146px;
            }

            input::placeholder, textarea::placeholder {
              padding-left: 15px;
            }

            textarea::placeholder {
              padding-top: 10px;
            }

            button {
              padding: 10px 25px;
              border-radius: 16px;
              color: #343838;
              font-weight: 700;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        h2, p {
          display: none;
        }

        .container {
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 697px;
            height: 562px;
            margin-top: -30px;
            margin-left: 90px;
            transform: rotate(-0deg);
          }

          form {
            margin-right: 100px;

            input, textarea {
              width: 304px;
            }

            input {
              height: 53px;
            }

            textarea {
              height: 183px;
            }

            button {
              height: 53px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        .container {
          margin-right: 190px;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      margin-bottom: 20px;

      > div {
        .container {
          img {
            width: 1050px;
            height: 723px;
            margin-left: 0;
            //margin-right: -250px;
          }

          form {
            //padding-right: 150px;
            margin-left: -180px;
            input, textarea {
              width: 430px;
            }
          }
        }
      }
    }
  }
</style>