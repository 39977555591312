<template>
  <section id="about" class="explore-hangman">
    <div>
      <h2>{{ c.title }}</h2>
      <img src="../assets/explore-img.png" alt="Exploreaza lumea cuvintelor cu Hangman" />
      <div class="align">
        <div>
          <div>
            <p>{{ c.p1 }}</p>
            <p>{{ c.p2 }}</p>
          </div>
          <p>{{ c.p3 }}</p>
        </div>
        <div class="img-div">
          <img src="../assets/hangman-pack.png" alt="" />
          <BaseButton :text="c.cta" @click="goToShop"  />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import {useRouter} from 'vue-router';
  import BaseButton from "@/components/BaseButton";

  const router = useRouter();
  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('exploreHangman'))

  const goToShop = () => {
    router.push("/shop");
  };
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .explore-hangman {
    @media only screen and (min-width: 0) {
      margin: 50px 16px 20px 16px;

      * {
        font-family: "Texturina", sans-serif;
        color: $brown;
      }

      > div {
        h2 {
          font-weight: 900;
        }

        h2 + img {
          width: 169px;
          height: 164px;
          margin-top: -20px;
          left: 0;
          position: absolute;
        }

        .align {
          p {
            margin-bottom: 25px;
          }

          .img-div {
            height: 181px;
            margin-top: 80px;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-radius: 15px;
            background-color: $beige;

            img {
              width: 253px;
              height: 162px;
              margin-top: -30px;
            }

            button {
              margin-top: 32px;
              padding: 5px 18px;
              background-color: $blue2;
              color: white;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 0 16px;

      > div {
        h2 {
          margin: 0;
        }

        h2 + img {
          margin-top: 30px;
        }

        .align {
          display: flex;
          flex-direction: row;
          align-items: center;

          > div {
            width: 65%;
            margin-right: 30px;

            div {
              display: flex;
              gap: 25px;
            }
          }

          div + div {
            width: 35%;
          }

          p {
            margin-bottom: -10px;
          }

          .img-div {
            width: 229px;
            margin-right: 0;

            img {
              width: 199px;
              height: 128px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin: 0 122px;

      > div {
        h2 {
          margin-bottom: 0;
        }

        h2 + img {
          margin-top: 0;
        }

        .align {
          > div {
            width: 70%;
            margin-right: 20px;
          }

          div + div {
            width: 30%;
          }

          .img-div {
            button {
              margin-top: 25px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      max-width: 1300px;
      margin: 0 auto;

      > div {
        h2 + img {
          margin-top: 50px;
        }

        .align {
          > div {
            width: 65%;
            margin-right: 20px;
          }

          div + div {
            width: 35%;
          }

          .img-div {
            width: 432px;
            margin-top: 150px;

            img {
              width: 396px;
              height: 245px;
              margin-top: -150px;
            }
          }
        }
      }
    }
  }
</style>