<template>
  <section id="why-to-play" class="why-to-play">
    <div>
      <h2>{{ c.title }}</h2>
      <div>
        <WhyToPlayItem
            v-for="(reason, index) in c.reasons"
            :key="index"
            :img="reason.img"
            :altText="reason.altText"
            :title="reason.title"
            :p="reason.p"
            :class="{
              'first-item': index === 0,
              'last-item': index === 1
            }"/>
      </div>
      <button @click="goToJunior">{{ c.cta }}<img src="../assets/icons/discover-game.png" alt="" /></button>
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import { useRouter } from 'vue-router';
  import WhyToPlayItem from "@/components/WhyToPlayItem";

  const { getters } = useStore()
  const router = useRouter();
  const c = computed(() => getters.sectionContent('whyToPlay'))

  const goToJunior = () => {
    router.push('/txto-junior');
  }
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .why-to-play {
    @media only screen and (min-width: 0px) {
      margin: 70px 23px 30px 23px;
      background-color: $blue;
      color: white;
      border-radius: 25px;
      padding: 10px 25px 40px 25px;

      div {
        display: flex;
        flex-direction: column;

        h2 {
          text-align: center;
        }

        h2 + div {
          .first-item {
            img {
              width: 139px;
              height: 125px;
              margin-bottom: 20px;
              align-self: center;
            }
          }

          .last-item {
            img {
              width: 163px;
              height: 149px;
              margin: -10px 0 5px 0;
              align-self: center;
            }
          }
        }

        button {
          padding: 8px 15px;
          margin-top: 10px;
          align-self: center;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          color: $blue2;
          font-weight: 700;
          cursor: pointer;

          img {
            width: 24px;
            height: 24px;
            margin-left: 15px;
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 40px 16px 30px 16px;
      padding: 10px 70px 40px 70px;

      > div {
        h2 {
          font-weight: 700;
        }

        h2 + div {
          display: flex;
          flex-direction: row;

          .first-item {
            padding-right: 40px;

            img {
              margin-bottom: 30px;
            }
          }

          .last-item {
            padding-left: 30px;

            img {
              margin-bottom: 17px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {}

    @media only screen and (min-width: 1440px) {
      padding: 10px 188px 40px 188px;

      div {
        h2 + div {
          .first-item {
            padding-right: 90px;
          }

          .last-item {
            padding-left: 90px;
          }
        }
      }
    }
  }
</style>