import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import JuniorView from '@/views/JuniorView.vue'
import HangmanView from '@/views/HangmanView.vue'
import ShopView from '@/views/ShopView.vue'

const routes = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/txto-junior',
    name: 'txto-junior',
    component: JuniorView
  },
  {
    path: '/hangman',
    name: 'hangman',
    component: HangmanView
  },
  {
    path: '/shop',
    name: 'shop',
    component: ShopView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router
