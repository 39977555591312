<template>
  <section class="header-shop">
    <div>
      <img class="logo" src="../assets/logo-txto.png" alt="" />
      <ul>
        <li v-for="page in c.pages" :key="page.id">
          <router-link :to="'/' + page.id">{{ page.title }}</router-link>
        </li>
      </ul>
    </div>
  </section>
</template>

<script setup>
  import {computed, ref} from 'vue';
  import {useStore} from 'vuex'

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('footer'))
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .header-shop {
    @media only screen and (min-width: 0) {
      background-color: $blue2;
      width: 100%;
      padding: 20px;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      box-shadow: 2px 2px 5px 2px $beige;
      border-bottom-right-radius: 25px;
      border-bottom-left-radius: 25px;

      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .logo {
          width: 60px;
        }

        ul {
          display: flex;
          list-style: none;

          li {
            a {
              margin: 0 8px;
              text-decoration: none;
              color: white;
              cursor: pointer;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > div {
        ul {
          li {
            a {
              margin: 0 15px;
            }
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > div {
        .logo {
          width: 80px;
        }

        ul {
          li {
            a {
              font-size: 2rem;
            }
          }
        }
      }
    }
  }
</style>