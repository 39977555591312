import { createStore } from 'vuex'
import { content } from './content'

export default createStore({
  state: {
    lang: 'ro',
    page: 'landingPage'
  },
  getters: {
    sectionContent: (state) => (section) => content[state.lang][state.page][section],
    juniorPage: (state) => (section) => content[state.lang]['junior'][section],
    hangmanPage: (state) => (section) => content[state.lang]['hangman'][section],
    shopPage: (state) => (section) => content[state.lang]['shop'][section]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
