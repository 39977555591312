<template>
  <section class="carousel-two">
    <div class="carousel-wrapper">
      <div class="align-carousel">
        <div class="carousel">
          <div id="slides">
            <div v-for="slide in slides" :key="slide.id" class="slide">
              <img class="slide-img" :src="slide.src" :alt="slide.alt" />
            </div>
          </div>
        </div>
      </div>

      <div class="buttons-hangman">
        <button
            @click="moveSlideTwo('prev')"
            :disabled="centerIndex === 0"
            class="carousel-button left">
          <img src="../assets/icons/left-arrow2.png" alt="Previous slide" />
        </button>

        <div>
          <p>{{ slides[centerIndex].p }}</p>
        </div>

        <button
            @click="moveSlideTwo('next')"
            :disabled="centerIndex === slides.length - 1"
            class="carousel-button right">
          <img src="../assets/icons/right-arrow2.png" alt="Next slide" />
        </button>
      </div>
    </div>
  </section>
</template>

<script setup>
  import {ref, onMounted, computed, onUnmounted, nextTick} from 'vue';
  import {useStore} from "vuex";

  const { getters } = useStore()
  const c = computed(() => getters.hangmanPage( 'punishmentCards'))

  const props = defineProps({
    slides: Array,
  });

  const transform = ref(0);
  const centerIndex = ref(1);

  const moveSlideTwo = (direction) => {
    const slideCount = props.slides.length;
    const image = document.querySelector(".slide-img");
    const slides = document.getElementById("slides");
    const width = image.clientWidth;

    if (direction === 'next') {
      centerIndex.value++;
      transform.value -= width;
    } else if (direction === 'prev') {
      centerIndex.value--;
      transform.value += width;
    }
    slides.style.transform = `translateX(${transform.value}px)`;

    const slidess = document.querySelectorAll('.slide');
    const scaleFactor = 1.2;

    slidess.forEach((slide, index) => {
      const distanceFromCenter = Math.abs((index - centerIndex.value + slideCount) % slideCount);

      const scale = distanceFromCenter === 0 ? scaleFactor : 1;

      slide.style.transform = `scale(${scale})`;
      slide.style.zIndex = distanceFromCenter === 0 ? '10' : '1';
    });
  };

  onMounted(async () => {
    await nextTick();

    const image = document.querySelector(".slide-img");
    if (image.complete) {
      initializeCarousel();
    } else {
      image.onload = initializeCarousel;
    }

    window.addEventListener('resize', initializeCarousel);
  });

  const initializeCarousel = () => {
    const slides = document.querySelectorAll('.slide');
    const slideCount = props.slides.length;
    const scaleFactor = 1.2;

    const image = document.querySelector(".slide-img");
    const width = image.clientWidth + 15;
    transform.value = -centerIndex.value * width;
    const slidesContainer = document.getElementById("slides");
    slidesContainer.style.transform = `translateX(${transform.value}px)`;

    slides.forEach((slide, index) => {
      const distanceFromCenter = Math.abs((index - centerIndex.value + slideCount) % slideCount);
      const scale = distanceFromCenter === 0 ? scaleFactor : 1;
      slide.style.transform = `scale(${scale})`;
      slide.style.zIndex = distanceFromCenter === 0 ? '10' : '1';
    });
  };

  onUnmounted(() => {
    window.removeEventListener('resize', initializeCarousel);
  });
</script>

<style lang="scss">
@import '../styles/vars.scss';

.carousel-two {
  @media only screen and (min-width: 0) {
    .carousel-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .align-carousel {
        display: flex;
        align-items: center;

        .carousel {
          width: 80px;

          #slides {
            display: flex;
            transition: transform 0.5s ease;

            > div {
              display: flex;
              align-items: center;
            }

            > div:nth-child(1){
              margin-right: 10px;
            }

            > div:nth-child(3){
              margin-left: 10px;
            }
          }

          .slide {
            min-width: 100%;
            margin: 0;
            transition: transform 0.5s ease, z-index 0.5s ease;
            box-sizing: border-box;
          }

          .slide-img {
            width: 90px;
            height: 135px;
          }
        }
      }

      .buttons-hangman {
        margin: 35px 23px 0 23px;
        display: flex;
        align-items: center;
        justify-content: center;

        .carousel-button {
          margin-top: 10px;
          padding: 0;
          background-color: transparent;

          img {
            width: 37px;
            height: 32px;
            cursor: pointer;
          }
        }

        .left {
          margin-right: -20px;
          z-index: 1;
        }

        .right {
          margin-left: -20px;
          z-index: 1;
        }

        div {
          width: 274px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: $blue2;
          border-radius: 15px;

          p {
            width: 120px;
            margin: 10px 0;
            text-align: center;
            font-size: 1.2rem;
            font-weight: 700;
            color: white;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .carousel-wrapper {
      .align-carousel {
        .carousel {
          width: 165px;
          margin: 50px 0 0 20px;

          #slides {
            > div:nth-child(1){
              margin-right: 0;
            }

            > div:nth-child(3){
              margin-left: 0;
            }
          }

          .slide-img {
            width: 174px;
            height: 260px;
          }
        }
      }

      .buttons-hangman {
        margin-top: 60px;

        .carousel-button {
          img {
            width: 46px;
            height: 40px;
          }
        }

        div {
          width: 323px;

          p {
            width: 170px;
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .carousel-wrapper {
      .align-carousel {
        .carousel {
          width: 200px;
          margin-left: 60px;

          .slide-img {
            width: 238px;
            height: 355px;
          }
        }
      }

      .buttons-hangman {
        .carousel-button {
          img {
            width: 55px;
            height: 48px;
          }
        }

        .left {
          margin-right: -30px;
        }

        .right {
          margin-left: -30px;
        }

        div {
          width: 653px;

          p {
            width: 350px;
            font-size: 2.4rem;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    .carousel-wrapper {
      .align-carousel {
        .carousel {
          width: 210px;
          margin-left: 60px;

          .slide-img {
            width: 247px;
            height: 369px;
          }
        }
      }

      .buttons-hangman {
        div {
          width: 888px;

          p {
            width: 300px;
          }
        }
      }
    }
  }
}
</style>

