<template>
  <section id="punishment" class="punsihment-cards">
    <img src="../assets/dino.png" alt="" />
    <div>
      <h2>{{ c.title }}</h2>
      <CarouselTwo :slides="slides" />
    </div>
  </section>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";
  import CarouselTwo from "@/components/CarouselTwo";

  const { getters } = useStore()
  const c = computed(() => getters.hangmanPage( 'punishmentCards'))

  const slides = ref([
    { id: 1, src: "./cards/4.png", alt: 'Txto stimuleaza gandirea', p: 'first' },
    { id: 2, src: "./cards/5.png", alt: 'Txto stimuleaza empatia', p: 'Două ture de trecut sub masă legat la ochi' },
    { id: 3, src: "./cards/6.png", alt: 'Txto stimuleaza buna dispozitie', p: 'third'  }
  ]);
</script>

<style lang="scss">
  .punsihment-cards {
    @media only screen and (min-width: 0) {
      margin-top: 50px;
      overflow: hidden;
      position: relative;

      > img {
        width: 137px;
        right: 0;
        position: absolute;
        z-index: -1;
      }

      > div {
        margin: 20px 23px;

        h2 {
          margin-bottom: 40px;
          text-align: center;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      > img {
        width: 246px;
        top: -20px;
      }

      > div {
        h2 {
          margin-top: 50px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      > img {
        width: 276px;
      }
    }

    @media only screen and (min-width: 1440px) {
      > img {
        width: 292px;
        top: 10px;
      }
    }
  }
</style>