<template>
  <section id="home" class="hero-junior">
    <div>
      <div class="text">
        <h1>{{ c.title }}</h1>
        <p>{{ c.p }}</p>
        <BaseButton :text="c.cta" />
      </div>
      <div class="images">
        <img src="../assets/blue-shape4.png" alt="" />
        <img src="../assets/txto-pack.png" alt="" />
      </div>
      <BaseButton :text="c.cta" />
    </div>
  </section>
</template>

<script setup>
  import {computed} from "vue";
  import {useStore} from "vuex";
  import BaseButton from "@/components/BaseButton";

  const { getters } = useStore()
  const c = computed(() => getters.juniorPage( 'hero'))
</script>

<style lang="scss">
  .hero-junior {
    @media only screen and (min-width: 0) {
      margin: 0 23px;

      > div {
        display: flex;
        flex-direction: column;

        .text {
          h1 {
            margin-bottom: 0;
            font-size: 2.4rem;
          }

          .base-button {
            display: none;
          }
        }

        .images {
          margin: 20px 0 50px 0;
          display: flex;
          align-items: center;
          justify-content: center;

          img:first-child {
            width: 189px;
            height: 168px;
            position: absolute;
          }

          img:last-child {
            width: 165px;
            height: 147px;
            position: relative;
          }
        }

        .base-button {
          align-self: center;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      margin: 0 16px;

      > div {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        .text {
          margin-left: 35px;

          h1 {
            margin-top: 0;
          }

          .base-button {
            display: flex;
          }
        }

        .images {
          img:first-child {
            width: 356px;
            height: 317px;
            margin-left: 16px;
          }

          img:last-child {
            width: 323px;
            height: 285px;
          }
        }

        .base-button {
          display: none;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      margin: 0 82px;

      > div {
        .text {
          margin-left: 50px;
        }

        .base-button {
          font-size: 2rem;
        }
      }
    }

    @media only screen and (min-width: 1440px) {
      margin: 0 48px;

      > div {
        .text {
          margin-left: 100px;

          h1 {
            font-size: 3.2rem;
          }
        }
        .images {
          img:first-child {
            width: 583px;
            height: 520px;
            margin-left: 48px;
          }

          img:last-child {
            width: 520px;
            height: 461px;
          }
        }
      }
    }
  }
</style>