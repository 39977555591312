<template>
  <section class="hero-banner">
    <div>
      <div class="logo-container">
        <img class="logo" src="../assets/logo-txto.png" alt="Play time" />
      </div>
      <div class="image-container">
        <img src="../assets/hero-image.png" />
      </div>
      <h1>{{ c.title }}</h1>
      <p>{{ c.subtitle }}</p>
      <select v-model="selectedOption" @change="navigateToPage">
        <option value="" disabled selected hidden>
          {{ c.cta.title }}
        </option>
        <option value="game1">{{ c.cta.game1 }}</option>
        <option value="game2">{{c. cta.game2 }}</option>
      </select>
    </div>
  </section>
</template>

<script setup>
  import {computed, ref} from "vue";
  import {useStore} from "vuex";
  import router from "@/router";

  const { getters } = useStore()
  const c = computed(() => getters.sectionContent('hero'))


  const selectedOption = ref("");

  const navigateToPage = () => {
    if (selectedOption.value === "game1") {
      router.push("/txto-junior");
    } else if (selectedOption.value === "game2") {
      router.push("/hangman");
    }
  };
</script>

<style lang="scss">
  @import '../styles/vars.scss';

  .hero-banner {
    @media only screen and (min-width: 0) {
     > div {
       display: flex;
       flex-direction: column;
       position: relative;
     }

      .logo-container {
        width: 87px;
        height: 44px;
        margin-left: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $orange2;
        position: absolute;
        z-index: 1;
        border-bottom-right-radius: 16px;
        border-bottom-left-radius: 16px;
        box-shadow: 0 5px 10px rgba(100, 99, 99, 0.99);

        .logo {
          width: 58px;
          height: 22px;
        }
      }

      .image-container {
        position: relative;
        width: 100%;
        height: 263px;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        overflow: hidden;
        box-shadow: 0 5px 10px rgba(100, 99, 99, 0.99);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }

      .image-container::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(0, 166, 219, 0) 26%, rgba(0, 166, 219, 0.62) 64%);
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
        pointer-events: none;
      }

      h1 {
        max-width: 200px;
        margin-left: 23px;
        position: absolute;
        top: 100px;
        color: white;
      }

      p {
        margin: 0 23px;
        padding: 20px 10px 35px 10px;
        text-align: center;
        background-color: #FFBA52;
        color: white;
        border-bottom-right-radius: 30px;
        border-bottom-left-radius: 30px;
      }

      select {
        width: 156px;
        margin: -20px 0 15px 0;
        align-self: center;
        appearance: none;
        background-color: $blue2;
        color: white;
        font-weight: 500;
        padding: 10px 50px 10px 17px;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        font-size: 1.6rem;
        background-image: url('../assets/icons/select-icon.png');
        background-repeat: no-repeat;
        background-position: right 20px center;
        background-size: 17px 21px;

        option {
          width: 156px;
          font-size: 1.6rem;
          background-color: $blue2;
          color: white;
          border: none;
          margin: 0;
        }
      }

      select:focus {
        outline: none;
        width: 156px;
      }
    }

    @media only screen and (min-width: 768px) {
      .image-container {
        height: 336px;
      }

      h1 {
        max-width: 260px;
        margin-left: 16px;
      }

      p {
        margin: 0 16px;
      }
    }

    @media only screen and (min-width: 1024px) {
      .logo-container {
        width: 146px;
        height: 73px;

        .logo {
          width: 97px;
          height: 36px;
        }
      }

      .image-container {
        height: 257px;
      }

      h1 {
        top: 60px;
      }
    }

    @media only screen and (min-width: 1440px) {
      .logo-container {
        width: 204px;
        height: 102px;

        .logo {
          width: 136px;
          height: 51px;
        }
      }

      .image-container {
        height: 425px;
      }

      h1 {
        max-width: 280px;
        top: 160px;
        margin-left: 48px;
      }

      p {
        margin: 0 48px;
        padding: 20px 200px 35px 200px;
      }
    }
  }
</style>
